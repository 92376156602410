// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.

import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
window.jQuery = $;
window.$ = $;

import "@fortawesome/fontawesome-free/css/all.css";
import 'arctic_admin'
import 'vendor/datepicker/ar/datepicker'
import 'vendor/daterange.js.erb'
import 'prices_page'

import Plotly from 'plotly.js-dist';
window.Plotly = Plotly;

import moment from 'moment';
window.moment = moment;

import 'daterangepicker';
import 'leaflet'
import 'activeadmin_addons/all';

import autocomplete from 'autocomplete.js';
window.autocomplete = autocomplete;

import 'cartona.js.erb';
import 'custom_arctic_admin';
import 'filter_rtl';
import "search_and_filter_items.js.erb";
import 'polygons_map.js.erb';
import 'distribution_routes/form';
import 'installment_form_requests_rejections/form';
import 'delivery_fees/form';
import 'batch_upload';
import 'retailer_groups/map.js.erb';
import 'leaflet_js/map.js';
import 'unpublishing_reasons/form';
import 'elastic_search.js';
import 'leaflet_js/preview_map.js';
import 'google_analytics.js.erb';
import 'supplier_service_transactions/form';
import 'offer_product_pricing_validations.js';

require('activeadmin_quill_editor')
