$(document).ready(function () {
  // Apply styles to the button
  applyBtnStyles();
  // listen on Change of product_supplier_id add id to the btn (Create supplier offer product)
  $(document).on("change", "select[id='supplier_offer_product_product_supplier_id']", function () {
    // get the select element
    const select = $(this);
    // get the value of the select element ( product_supplier_id )
    const product_supplier_id = select.val();
    // get the fieldset of the select element
    const fieldset = select.closest("fieldset");
    // get the validate button of the fieldset
    const validateBtn = fieldset.next('.offer-product-validations-btn-container').find("[id^='validate-form-btn-']");

    if (product_supplier_id) {
      // append the product_supplier_id to the id of the validate button
      validateBtn.attr("id", `validate-form-btn-${product_supplier_id}`);
    } else {
      // remove the product_supplier_id from the id of the validate button
      validateBtn.attr("id", `validate-form-btn-id`);
    }

  });

  // listen on Change of product_supplier_id add id to the btn (Create supplier offer product inside supplier offer)
  $(document).on("change", "select[id^='supplier_offer_offer_products_attributes_'][id$='_product_supplier_id']", function () {
    // get the select element
    const select = $(this);
    // get the value of the select element ( product_supplier_id )
    const product_supplier_id = select.val();
    // get the fieldset of the select element
    const fieldset = select.closest("fieldset");
    // get the validate button of the fieldset
    const validateBtn = fieldset.find("[id^='validate-form-btn-']");

    if (product_supplier_id) {
      // append the product_supplier_id to the id of the validate button
      validateBtn.attr("id", `validate-form-btn-${product_supplier_id}`);
    } else {
      // remove the product_supplier_id from the id of the validate button
      validateBtn.attr("id", `validate-form-btn-id`);
    }

  });

// listen on click of the validate button
  $(document).on("click", "button[id^='validate-form-btn-']", function () {
    // get the id of the button
    const id = $(this).attr("id");
    // get the product_supplier_id from the btn id
    const product_supplier_id = id.split("-").pop();
    if (product_supplier_id === "id") { // clicked with no selected product_supplier
      return;
    }

    // AJAX request to get the product_supplier with validations
    $.ajax({
      url: `/admin/product_suppliers/${product_supplier_id}/offer_product_validations`,
      method: "GET",
      dataType: 'json',
      delay: 250,
      success: function (response) {
          /* FOR TESTING */
          // var product_validations = {
          //   max_qty: [1,2,3],
          //   subsidy_per_piece: [0, 6.25, 6.5],
          //   max_price_with_subsidy: [465.5, 476, 480.25],
          //   max_price_without_subsidy: [465.5, 477.75, 482]
          // };
          // product_validations = response.offer_product_pricing_validation || product_validations;

          product_validations = response.offer_pricing_validation;
          if (product_validations) {
            const formattedProductValidations = formatProductValidation(product_validations);
            const htmlContent = getPopUpContent(true, response.product_supplier, formattedProductValidations);
            showPopUp(htmlContent);
          } else {
            const htmlContent = getPopUpContent(false, response.product_supplier);
            showPopUp(htmlContent);
          }

      },
      error: function (xhr, status, error) {
          console.error("AJAX Error Product Supplier:", error);
          // Handle error response
      }
    });
  });

});

// Format product validations into an array of objects
function formatProductValidation(productValidations){
  let formattedProductValidations = productValidations.max_qty.map((_, index) => ({
    max_qty: productValidations.max_qty[index],
    subsidy_per_piece: productValidations.subsidy_per_piece[index],
    recommended_supplier_product_price: productValidations.recommended_supplier_product_price[index],
    max_price_with_subsidy: productValidations.max_price_with_subsidy[index],
    max_price_without_subsidy: productValidations.max_price_without_subsidy[index]
  }));

  return formattedProductValidations;
}

// Get the popup content based on the product validations
function getPopUpContent(hasValidation, product_supplier, product_validations = []){
  let data = getDataTranslated();
  let htmlContent = '';
  if (hasValidation) {
    htmlContent = `
  <div id="ajax-popup" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; background: white; border: 1px solid #CCC; border-radius: 10px; z-index: 1000;">
    <div id="popup-content">
      <h3 style="text-align: center;">${data.title.validations}</h3>
      <h4 style="text-align: center;">${product_supplier.name}</h4>
      <!-- <h4> ID: ${product_supplier.id}</h4> -->

      <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr>
            <th style="border: 1px solid #CCC; padding: 8px;">
            ${ data.headers.max_qty }
            </th>
            <th style="border: 1px solid #CCC; padding: 8px;">
            ${ data.headers.max_price_without_subsidy }
            </th>
            <th style="border: 1px solid #CCC; padding: 8px;">
            ${ data.headers.recommended_supplier_product_price }
            </th>
            <th style="border: 1px solid #CCC; padding: 8px;">
            ${ data.headers.subsidy_per_piece }
            </th>
            <th style="border: 1px solid #CCC; padding: 8px;">
            ${ data.headers.max_price_with_subsidy }
            </th>
          </tr>
        </thead>
        <tbody>
          ${product_validations.map((product_validation, index) => `
              <tr>
                <td style="border: 1px solid #CCC; padding: 8px;">${product_validation.max_qty}</td>
                <td style="border: 1px solid #CCC; padding: 8px;">${product_validation.max_price_without_subsidy}</td>
                <td style="border: 1px solid #CCC; padding: 8px;">${product_validation.recommended_supplier_product_price}</td>
                <td style="border: 1px solid #CCC; padding: 8px;">${product_validation.subsidy_per_piece}</td>
                <td style="border: 1px solid #CCC; padding: 8px;">${product_validation.max_price_with_subsidy}</td>
              </tr>
            `).join('')}
        </tbody>
      </table>
    </div>
    <button
      id="close-popup" style="margin-top: 15px; padding: 5px 10px; border-radius: 5px; border: 1px solid #CCC; cursor: pointer;">
        ${data.closeBtn}
      </button>
  </div>
  <div id="overlay" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 999;"></div>
`;
  } else {
  htmlContent = `
    <div id="ajax-popup" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; background: white; border: 1px solid #CCC; border-radius: 10px; z-index: 1000;">
      <div id="popup-content">
        <h3 styl="text-align: center">${data.title.emptyValidations}</h3>
        <h4 style="text-align: center;">${product_supplier.name}</h4>
      </div>
      <button id="close-popup" style="padding: 5px 10px; border-radius: 5px; border: 1px solid #CCC; cursor: pointer">
      ${data.closeBtn}
      </button>
    </div>
    <div id="overlay" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 999;"></div>
  `;
  }

  return htmlContent;
}

// Show the popup
function showPopUp(popupHTML){
  $('body').append(popupHTML);

  // Close popup on button click
  $(document).on("click", "#close-popup", function () {
    $('#ajax-popup').remove(); // Remove popup from DOM
    $('#overlay').remove(); // Remove overlay from DOM
  });
}

function getDataTranslated(){
  let lang = $('html').attr('lang');
  let translations = {};

  if (lang === 'en') {
    translations = {
      title: {
        emptyValidations: 'No Product Validations',
        validations: 'Product Validations'
      },
      headers: {
        max_qty: 'Max Order Qauntity',
        max_price_without_subsidy: 'Max Price Without Subsidy',
        recommended_supplier_product_price: 'Recommended Supplier Price',
        subsidy_per_piece: ' Max Subsidy Per Piece',
        max_price_with_subsidy: 'Max Price With Subsidy',
      },
      closeBtn: 'Ok'
    };
  } else if (lang === 'ar') {
    translations = {
      title: {
        emptyValidations: 'لا توجد بيانات تحقق للمنتج',
        validations: 'بيانات التحقق المنتج'
      },
      headers: {
        max_qty: 'الكميات',
        max_price_without_subsidy:'سعر المورد (بدون دعم)',
        recommended_supplier_product_price: 'سعر المورد (للدعم)',
        subsidy_per_piece: 'الحد الاقصى للدعم',
        max_price_with_subsidy: 'سعر العميل (بعد الدعم)',

      },
      closeBtn: 'حسنا'
    }
  }

  return translations;
}

function applyBtnStyles(){
  // CASE 1: Apply styles to the button if it exists
  addBtnStyles();

  // CASE 2: Apply styles to the button if it is added dynamically

  // Target the parent element where the button will be added
  const targetNode = document.body;

  // Create a MutationObserver instance
  const observer = new MutationObserver(function (mutationsList) {
      mutationsList.forEach(function (mutation) {
          // Check if the button is added
          if ($(mutation.addedNodes).find('.offer-product-validations-btn').length > 0) {
              // Apply styles to the button
              addBtnStyles();
          }
      });
  });
  // Configure the observer to watch for child nodes being added
  observer.observe(targetNode, { childList: true, subtree: true });
}

function addBtnStyles(){
  $('.offer-product-validations-btn').css({
    'background-color': '#1976D2',
    'color': 'white',
    'border-radius': '4px',
    'display': 'inline-block',
    'transition': 'border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear',
    'width': 'fit-content',
    'padding': '5px',
    'font-size': '15px',
    'font-weight': '500',
    'box-shadow': '0 1px 0 rgba(0, 0, 0, 0.05)',
    'border': 'none',
    'cursor': 'pointer'
  });
}
