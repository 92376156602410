$(function () {
  if ($(".supplier_distribution_routes").length) {
    $('#distribution_route_city_id').on('select2:select', function (e) {
      var selected_city_id = e.params.data.id;
      $("#distribution_route_area_ids").data('query-extras', `{"q":{"city_id_eq": ${selected_city_id}}}`);
    });
  }
})

$(function () {
  $('#offer_link_type').on('select2:select', function (e) {
    var selected_offer_type = e.params.data.id;
    if (selected_offer_type == 'other') {
      $('#offer_linkable_id_container').hide();
      $('#offer_link_container').show();
    } else {
      $('#offer_link_container').hide();
      $('#offer_linkable_id_container').show();
      var options = $('#offer_linkable_id').data('select2').options.options;
      var offerScope = $('#offer_link_type').val();
      options.responseRoot = offerScope;
      options.url = `/admin/${offerScope}`;
      options.ajax.url = `/admin/${offerScope}.json`;
      $('#offer_linkable_id').select2(options);
    }
    $('#offer_linkable_id').val('').trigger('change');
    $('#offer_link').val('')
  });
})

// new referral configs form
$(function () {
  if ($('body').hasClass('admin_referral_configurations') &&
    ($('body').hasClass('new') || $('body').hasClass('create'))) {
    $('#referral_config_owner_id_container').hide();
    $('#referral_config_owner_type').on('select2:select', function (e) {
      var selected_owner_type = e.params.data.id;

      $('#referral_config_owner_id_container').show();
      var options = $('#referral_config_owner_id').data('select2').options.options;
      var ownerScope = $('#referral_config_owner_type').val();
      modelToUrl = {
        
        'CoreEngine::Supplier': 'suppliers',
        
        'CoreEngine::Retailer': 'retailers',
        
        'CoreEngine::AdminUser': 'admin_users',
        
        'CoreEngine::SupplierUser': 'supplier_users',
        
      };
      ownerScope = modelToUrl[ownerScope];
      options.responseRoot = ownerScope;
      options.url = `/admin/${ownerScope}`;
      var referral_owner_id = $('#referral_config_owner_id');
      referral_owner_id.data('url', `/admin/${ownerScope}`);
      referral_owner_id.data('response-root', ownerScope);
      referral_owner_id.data('fields', ["name", "email", "first_name", "last_name"]);
      referral_owner_id.data('display-name', 'name');
      referral_owner_id.data('predicate', 'cont');
      referral_owner_id.data('width', '100%');
      referral_owner_id.data('minimum-input-length', 1);
      referral_owner_id.data('order', 'name_asc');
      referral_owner_id.data('query-extras', 'null');
      $('#referral_config_owner_id').select2(options);
      $('#referral_config_owner_id').val('').trigger('change');

      $('#referral_config_referrer_type').val(selected_owner_type);
      $('#referral_config_referrer_type').select2().trigger('change');
    });

    $('#referral_config_owner_type').on('select2:unselect', function (e) {
      $('#referral_config_owner_id_container').hide();
      $('#referral_config_owner_id').val('').trigger('change');

      $('#referral_config_referrer_type').val('');
      $('#referral_config_referrer_type').select2().trigger('change');
    });
  }

})
// https://local.my.cartona.com:5000/supplier/areas?q[city_id_eq]=1&order=name_asc
$(function () {
  $('.new.admin_suppliers.admin_namespace .has_many_container.commission_rates a').click()
});

// event show page task form
$(function () {
  if ($('body').hasClass('show admin_events')) {
    $('body').toggleClass('show edit');
  }

  if (!$('#done-checkbox').prop('checked'))
    $('#task-result-container').hide();

  $('#done-checkbox').on('click', function (e) {
    checked = e.target.checked;
    if (checked) $('#task-result-container').show();
    else $('#task-result-container').hide();
  })

  // now we always show the reminder task form regardless of the task result

  // // task reminder select
  // var reminder = $('#reminder-task-container').hide();
  // reminder.find('select').val(""); // clear selected item

  // $('#task-result-select').change(function () {
  //   var label = $('#task-result-select :selected').parent().attr('label');
  //   if(label == 'unsuccessful' || label == 'غير ناجح') {
  //     reminder.show();
  //   } else {
  //     // clear selected item
  //     reminder.find('select').val("");
  //     reminder.find('select').trigger('change');
  //     reminder.hide();
  //   }

  // });
})

// event show page supplier panel (show more/show less)
$(function () {
  var numShown = 10; // how many rows to show on show less
  var shouldShowMore = true

  var $table = $('.supplier_summary .attributes_table.core_engine_supplier').find('tbody');
  var numRows = $table.find('tr').length;

  $table.find('tr:gt(' + (numShown - 1) + ')').hide().end()
    .after('<tbody id="more"><tr><td colspan=2""><a>Show More</a></td></tr></tbody>');

  $('#more').click(function () {
    if (shouldShowMore) {
      $table.find('tr:lt(' + numRows + ')').show();
      $('#more tr a').html('Show Less');
      shouldShowMore = false;
    } else {
      $table.find('tr:gt(' + (numShown - 1) + ')').hide().end();
      $('#more tr a').html('Show More');
      shouldShowMore = true;
    }
  });

});

$(function () {
  $('#supplier_off_days').select2({maximumSelectionLength: 6});
  const table_rows = $('table#supplier-working-hours tbody tr')
  $('#supplier_off_days').on('change', function () {
    updateSupplierWorkingHours(table_rows);
  });
  updateSupplierWorkingHours(table_rows);
});

function updateSupplierWorkingHours(table_rows) {
  table_rows.each(function (index, row) {
    let selected_days = $('#supplier_off_days').val()
    if (selected_days.includes(index.toString())) {
      $(row).find('td input[type=time]').prop('disabled', true)
      $(row).find('td').css('opacity', .4)
      $(row).find('td input[type=time]').val('')  // RESET TIME VALUE
    } else {
      let timeInputs = $(row).find('td input[type=time]')
      timeInputs.prop('disabled', false)
      $(row).find('td').css('opacity', 1)
      // SET DEFAULT TIME VALUE
      timeInputs.each(function (index, input) {
        if ($(input).val() == '') {
          if (index == 0) {
            $(input).val('10:00')
          } else {
            $(input).val('17:00')
          }
        }
      })
    }
  });
}

$(function () {
  $('.home-block-option[data-options-for]').parent().hide()
  if ($('#home_block_block_type').val()) {
    $('.home-block-option[data-options-for=' + $('#home_block_block_type').val() + ']').parent().show()
  }
  $('#home_block_block_type').on('select2:select', function (e) {
    $('.home-block-option[data-options-for]').parent().hide()
    $('.home-block-option[data-options-for=' + e.params.data.id + ']').parent().show()
  });
});

$(function () {
  var callbackFun = function (e) {
    var selected_content_type = $('#home_block_content_type').val();
    if ($('.has_many_fields .search-select-input').length) {
      var options = $('.has_many_fields .search-select-input').data('select2').options.options;
      options.responseRoot = selected_content_type;
      options.url = `/admin/${selected_content_type}`;
      options.ajax.url = `/admin/${selected_content_type}.json`;
      $('.has_many_fields .search-select-input').select2(options);
      $('.has_many_fields .search-select-input').val('')
      $('.has_many_fields .search-select-input').val('').trigger('change');
    }
  }
  $('#home_block_content_type').on('select2:select', callbackFun);
  $('.block_contents a.button.has_many_add').click(function (e) {
    setTimeout(callbackFun, 1000)
  });
});

// retailer verify signup page
$(function () {
  if ($('body').hasClass('admin_retailer_signup')) {
    $('body').toggleClass('index edit');
    $('.tel-input').css('padding-left', '78px');
  }
});

// print order page
$(function () {
  if ($('body').hasClass('admin_order_receipt') ||
    $('body').hasClass('supplier_order_receipt')) {
    print();
  }
});

$(function () {
  $('#supplier_offer_product_supplier_id.hidden').parent().remove();
  $('a.batch_action').on('click', function (e) {
    $('#dialog_confirm select').select2();
    $('.ui-dialog.ui-corner-all.ui-widget').unbind('focus')
  });
});

// new/edit sponsored_item form
$(function () {
  if ($('body').hasClass('admin_sponsored_items') &&
    ($('body').hasClass('new') || $('body').hasClass('create') || $('body').hasClass('edit'))) {
    $("#sponsored_item_item_type").val(null).trigger("change");

    $('#sponsored_item_item_type').on('select2:select', function (e) {
      $('#sponsored_item_item_id_container').show();
      var options = $('#sponsored_item_item_id').data('select2').options.options;
      var itemScope = $('#sponsored_item_item_type').val();
      var modelToUrl = {
        'CoreEngine::Supplier': 'suppliers',
        'CoreEngine::Product': 'products',
        'CoreEngine::ProductSupplier': 'product_suppliers',
      }
      var scope = modelToUrl[itemScope];
      options.responseRoot = scope;
      options.url = `/admin/${scope}`;
//      options.ajax.url = `/admin/${scope}.json`;

      var sponsored_item_item_id = $('#sponsored_item_item_id');
      sponsored_item_item_id.data('url', `/admin/${scope}`);
      sponsored_item_item_id.data('response-root', scope);
      sponsored_item_item_id.data('fields', ["name"]);
      sponsored_item_item_id.data('display-name', 'name');
      sponsored_item_item_id.data('predicate', 'cont');
      sponsored_item_item_id.data('width', '100%');
      sponsored_item_item_id.data('minimum-input-length', 1);
      sponsored_item_item_id.data('order', 'name_asc');
      sponsored_item_item_id.data('query-extras', 'null');

      $('#sponsored_item_item_id').select2(options);
      $('#sponsored_item_item_id').val('').trigger('change');
    });

    $('#sponsored_item_item_type').on('select2:unselect', function (e) {
      $('#sponsored_item_item_id_container').hide();
      $('#sponsored_item_item_id').val('').trigger('change');
    });

    // set the selected value of the item id search select
    var select = $('#sponsored_item_item_id');
    var label = select.attr('data-aria-selected-label');
    var value = select.attr('data-aria-selected-value');
    if (label && value) {
      var option = `<option selected value="${value}">${label}</option>`;
      select.append(option);
    }

    // set the selected values of the distribution routes search select on update
    var select = $('#sponsored_item_distribution_route_ids');
    var routes = JSON.parse(select.attr('data-aria-selected'));
    if (routes) {
      routes.forEach((route) => {
        var option = `<option selected value="${route[1]}">${route[0]}</option>`;
        select.append(option);
      })
    }

    // set the selected values of the categories search select on update
    var select = $('#sponsored_item_category_ids');
    var categories = JSON.parse(select.attr('data-aria-selected'));
    if (categories) {
      categories.forEach((category) => {
        var option = `<option selected value="${category[1]}">${category[0]}</option>`;
        select.append(option);
      })
    }
  }
})


// Distribution routes page
// show different views to add area based on the selected option
// this function depends on the order of the elements in that page

$(function () {
  const body = $('body');
  if (
    (body.hasClass('admin_distribution_routes') || body.hasClass('supplier_distribution_routes') || body.hasClass('admin_retailer_groups'))
    && (body.hasClass('new') || body.hasClass('create') || body.hasClass('edit'))) {

    let pageName = body.hasClass('admin_distribution_routes') || body.hasClass('supplier_distribution_routes') ? 'distribution_route' : null;
    pageName = body.hasClass('admin_retailer_groups') ? 'retailer_group' : pageName;

    const kmlFileComponent = $(`#${pageName}_kml_file_input`);
    const selectAreaComponent = $(`#${pageName}_area_id_input`);
    const mapComponent = $('.polygon_coordinates_draw');
    const selectedOption = $(`#${pageName}_area_options`).val();
    if (body.hasClass('create')) {
      if (selectedOption === 'select_area') {
        kmlFileComponent.hide();
        mapComponent.hide();
      } else if (selectedOption === 'upload_file') {
        selectAreaComponent.hide();
        mapComponent.hide();
      } else if (selectedOption === 'draw_polygon') {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
      } else if (selectedOption === 'match_distribution_route_polygon') {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
        mapComponent.hide();
      }
    } else if (body.hasClass('edit')) {
      if (selectedOption === 'match_distribution_route_polygon') {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
        mapComponent.hide();
      } else {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
      }
    } else if (body.hasClass('new')) {
      if (selectedOption === 'match_distribution_route_polygon') {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
        mapComponent.hide();
      } else {
        kmlFileComponent.hide();
        selectAreaComponent.hide();
        mapComponent.hide();
      }
    }

    $(`#${pageName}_area_options_input`).on('select2:select', e => {
      const selectedOption = e.params.data.id;
      switch (selectedOption) {
        case 'select_area':
          $(`#${pageName}_kml_file`).val('')
          selectAreaComponent.show();
          kmlFileComponent.hide();
          mapComponent.hide();
          break;
        case 'upload_file':
          $(`#${pageName}_area_id`).empty()
          kmlFileComponent.show();
          selectAreaComponent.hide();
          mapComponent.hide();
          break;
        case 'draw_polygon':
          $(`#${pageName}_area_id`).empty()
          $(`#${pageName}_kml_file`).val('')
          mapComponent.show();
          kmlFileComponent.hide();
          selectAreaComponent.hide();
          break;
        case 'match_distribution_route_polygon':
          $(`#${pageName}_area_id`).empty()
          $(`#${pageName}_kml_file`).val('')
          mapComponent.hide();
          kmlFileComponent.hide();
          selectAreaComponent.hide();
          break;
      }
    });

    $(`#${pageName}_area_options_input`).on('select2:unselect', () => {
      kmlFileComponent.hide();
      selectAreaComponent.hide();
      if (body.hasClass('new') || body.hasClass('create')) {
        mapComponent.hide();
      }
    });
  }
});

// Smart Ordering page
$(function () {
  if ($('body').hasClass('admin_smart_ordering')) {

    // remove row btn
    $(document).on('click', '.remove-row-btn', function () {
      $(this).closest('#inputFormRow').remove();
      const prodId = $(this).closest('#inputFormRow').find('.product-id').val();
      $(document).find(`#smart_ordering_smart_ordering_input_dummy_products_ids_${prodId}`).remove();
    });

    // add quantity and unit inputs automatically when a new product is added to the selected_list input
    $('#smart_ordering_smart_ordering_input_dummy_products_ids').on('change', function () {
      const prodId = this.value;
      if (prodId) {
        const html = `
        <div class="row">
          <div class="columns">
            <div id="inputFormRow" class="smart-ordering-product-input-row">
              <div class="column">
                <input type="text" name="smart_ordering_smart_ordering_input[products_ids][]" class="form-control m-input product-id" placeholder="Enter product id" value="${prodId}" readonly>
              </div>
              <div class="column">
                <input type="text" name="smart_ordering_smart_ordering_input[products_quantities][]" class="form-control m-input quantity" placeholder="Enter quantity">
              </div>
              <div class="column" style="width: 15%">
                ` +
          `<select name="smart_ordering_smart_ordering_input[products_units][]"><option value="box">كرتونة</option>
<option value="can">علبة</option>
<option value="bottle">زجاجة</option>
<option value="kg">كيلو جرام</option>
<option value="piece">قطعة</option>
<option value="dozen">دستة</option>
<option value="bag">شنطة</option>
<option value="packet">باكت</option>
<option value="plate">صفيحة</option>
<option value="glass">كوب</option>
<option value="pallet">بالتة</option>
<option value="jar">برطمان</option>
<option value="shrink">شرنك</option>
<option value="ton">طن</option>
<option value="saddlebag">شكارة</option>
<option value="roll">رول</option>
<option value="tissue">منديل</option>
<option value="cone">كوز</option>
<option value="coil">لفة</option>
<option value="bunch">ربطة</option>
<option value="strip">شريط</option>
<option value="recharge_card">كارت شحن</option>
<option value="mat">حصيرة</option></select>`
          + `</div>
              <div class="column">
                <div class="input-group-append">
                  <button class="remove-row-btn" type="button">Remove</button>
                </div>
              </div>
            </div>
          </div>
        `;
        $('#newRow').append(html);
      }
    })
  }
});

// Supplier retailer classes page
$(function () {
  if ($('body').hasClass('new admin_supplier_retailer_classes')) {
    let element = $('#supplier_retailer_class_default_for_types_input .inline-hints');
    element.css('cursor', 'pointer');
    element.css('color', 'blue');

    element.click(function () {
      $("#default-for-types > option").prop("selected", "selected");// Select All Options
      $("#default-for-types").trigger("change");// Trigger change to select 2
    });

    $("#supplier_retailer_class_block_retailers").click(function () {
      if ($("#supplier_retailer_class_block_retailers").is(':checked')) {
        $('#default-for-types').val(null).trigger('change');
        $("#supplier_retailer_class_default_for_types_input").hide();
      } else {
        $("#supplier_retailer_class_default_for_types_input").show();
      }
    });
  }
});

// Suppliers page
$(function () {
  if ($('body').hasClass('new admin_suppliers') || $('body').hasClass('edit admin_suppliers')) {
    const element = $('#supplier_for_retailer_types_input .inline-hints');
    element.css('cursor', 'pointer');
    element.css('color', 'blue');

    element.click(function () {
      $("#select-all-retailer-types > option").prop("selected", "selected");// Select All Options
      $("#select-all-retailer-types").trigger("change");// Trigger change to select 2
    });
  }
});

// Products page
$(function () {
  if ($('body').hasClass('new admin_products') || $('body').hasClass('edit admin_products')) {
    const element = $('#product_for_retailer_types_input .inline-hints');
    element.css('cursor', 'pointer');
    element.css('color', 'blue');

    element.click(function () {
      $("#select-all-retailer-types > option").prop("selected", "selected");// Select All Options
      $("#select-all-retailer-types").trigger("change");// Trigger change to select 2
    });
  }
});

$(function () {
  if (($('body').hasClass('admin_retailer_groups'))) {
    const kmlFileComponent = $('#retailer_group_kml_file_input');
    kmlFileComponent.hide();
  }
});

$(function () {
  $('#instalment-plan-submit-btn').on('click', function (e) {
    published = $('#instalment-plan-published').prop('checked');
    to_date = new Date($('#instalment_plan_to_date').val()).getTime()
    condition = !published || to_date < new Date().getTime();

    if (condition) {
      if (!confirm('association for this plan will be unpublished if exist')) {
        e.preventDefault()
      }
    }
  })
})

$(function () {
  $('transfer-submit-btn').on('click', function (e) {

    if (!confirm('Are You sure you want to transfer?')) {
      e.preventDefault()
    }

  })
})

$(function () {
  $('#retailer-plan-published').on('change', function (e) {
    published = $('#retailer-plan-published').prop('checked');
    is_golden = $('#retailer-golden-plan').val()
    console.log("golden ", is_golden)
    console.log("published ", published)
    console.log("published && is_golden ", published && is_golden)
    console.log('priority ', $('#retailer-plan-priority').val())
    if (!published) {
      $('#retailer-plan-priority').val(100)
    } else if (published && is_golden == 'true') {
      $('#retailer-plan-priority').val(1)
    } else if (published && is_golden == 'false') {
      $('#retailer-plan-priority').val(2)
    }
  })
})


$(function () {
  function initializeRetailerFilterTypeHandling() {
    function handleFilterTypeVisibility(isStatic) {
      const staticTitleComponent = $('#manual_filter_static_title_input');
      const staticDistributionRouteComponent = $('#manual_filter_distribution_route_tags_input');
      const dynamicTitleComponent = $('#manual_filter_title_input');
      const iconInput = $('#retailer_filter_icon');
      const distributionRouteInput = $('#retailer_filter_distribution_route_ids');

      if (isStatic) {
        staticTitleComponent.show();
        dynamicTitleComponent.hide();
        staticDistributionRouteComponent.hide();
      } else {
        staticTitleComponent.hide();
        dynamicTitleComponent.show();
        staticDistributionRouteComponent.show();
      }

      const fieldsToDisable = [iconInput, distributionRouteInput];
      fieldsToDisable.forEach(field => {
        field.prop('disabled', isStatic);
        field.closest('.input').css('opacity', isStatic ? '0.5' : '1');
      });
    }

    if ($('body').hasClass('edit')) {
      const currentFilterType = $('#retailer_filter_filter_type').val();
      const isStatic = currentFilterType == 1;
      handleFilterTypeVisibility(isStatic);
    }

    $('#retailer_filter_filter_type').on('select2:select', function (e) {
      const filterTypeSelect = e.params.data.id;
      const isStatic = filterTypeSelect == 1;
      handleFilterTypeVisibility(isStatic);
    });
  }

  if ($('body').hasClass('admin_retailer_filters')) {
    initializeRetailerFilterTypeHandling();
  }
});
